const fileTypes = {
	'image/jpeg': 'JPEG',
	'image/png': 'PNG',
	'application/pdf': 'PDF',
	'text/plain': 'TXT',
	'application/msword': 'DOC',
	'application/vnd.ms-excel': 'XLS',
	'application/vnd.ms-powerpoint': 'PPT',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX'
};

const allStatus = [
	{ name: 'Requested', color: 'orange', id: 0 },
	{ name: 'Payment Requested', color: 'orange', id: 1 },
	{ name: 'Confirmed', color: 'blue', id: 2 },
	{ name: 'Done', color: 'green', id: 3 },
	{ name: 'Canceled', color: 'red', id: 4 },
	{ name: 'Protocol', color: 'orange', id: 6 },
	// { name: 'Rejected', color: 'purple' },
	// { name: 'Expired', color: 'grey' }
];

export { allStatus };
export default fileTypes;
