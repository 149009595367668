/* eslint-disable eqeqeq */
import { isObject, isEmpty, isArray, isNumber } from "lodash";
import Notification from "@iso/components/Notification";
import siteConfig from "@iso/config/site.config";
import { getDefaultPath } from "@iso/lib/helpers/url_sync";
import authActions from "@iso/redux/auth/actions";
const socketIOClient = require("socket.io-client");
const sailsIOClient = require("sails.io.js");
let io = sailsIOClient(socketIOClient);

if (io) {
  io.sails.url = siteConfig.sailsUrl;
  io.sails.autoConnect = false;
}

const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const actions = {
  CHAT_INIT: "CHAT_INIT",
  CHAT_INIT_SAGA: "CHAT_INIT_SAGA",
  CHAT_UPDATE_CHATROOM: "CHAT_UPDATE_CHATROOM",
  CHAT_TOGGLE_COMPOSE: "CHAT_TOGGLE_COMPOSE",
  CHAT_SET_TOGGLE_VIEW_PROFILE: "CHAT_SET_TOGGLE_VIEW_PROFILE",
  CHAT_SET_TOGGLE_COMPOSED_ID: "CHAT_SET_TOGGLE_COMPOSED_ID",
  CHAT_SEND_MESSAGE: "CHAT_SEND_MESSAGE",
  CHAT_UPDATE_CHATROOM_SAGA: "CHAT_UPDATE_CHATROOM_SAGA",
  TOGGLE_MOBILE_LIST: "TOGGLE_MOBILE_LIST",
  TOGGLE_MOBILE_PROFILE: "TOGGLE_MOBILE_PROFILE",
  RESTORE_DEMO_DATA: "RESTORE_DEMO_DATA",
  RESTORE_DEMO_DATA_DONE: "RESTORE_DEMO_DATA_DONE",
  UPDATE_NEW_USER_PROPS: "UPDATE_NEW_USER_PROPS",
  ADD_NEW_USER: "ADD_NEW_USER",
  ADD_NEW_USER_SAGA: "ADD_NEW_USER_SAGA",
  NEW_MESSAGE_SUCCESFULL: "NEW_MESSAGE_SUCCESFULL",
  SET_IO: "SET_IO",
  SET_CHAT_ROOM: "SET_CHAT_ROOM",
  SET_SELECTED_CHAT_ROOM: "SET_SELECTED_CHAT_ROOM",
  SET_MESSAGES: "SET_MESSAGES",
  SET_RECEIVED_MSG: "SET_RECEIVED_MSG",
  SET_TYPING: "SET_TYPING",
  CLEAR_CHAT_DATA: "CLEAR_CHAT_DATA",
  SET_CHAT_NOTIFICATION: "SET_CHAT_NOTIFICATION",

  initilization: () => (dispatch, getState) => {
    const IOSocket = getState().Chat.IOSocket;
    const token = getState().Auth.idToken;
    if (!IOSocket) {
      const socket = io.sails.connect();
      const headers = { ...defaultHeaders };
      if (token) headers.Authorization = `Bearer ${token}`;
      const options = {
        method: "POST",
        url: "/chat/connect",
        headers,
      };

      if (socket) {
        socket.request(options, function (resData, jwres) {
          console.log("Sails responded with: ", resData);
          console.log("with headers: ", jwres.headers);
          console.log("and with status code: ", jwres.statusCode);
          dispatch({
            type: actions.SET_IO,
            data: socket,
            socketData:
              isObject(resData) && isObject(resData.data) ? resData.data : null,
          });
          dispatch(actions.setChatNotification());
        });

        // RECIEVE MESSAGES
        socket.on("message", function (msg) {
          // console.log('message===>');
          // console.log(msg);
          if (isObject(msg) && !isEmpty(msg)) {
            dispatch(actions.receiveMsg(msg));
          }
        });

        // RECIEVE TYPING
        socket.on("isTyping", function (data) {
          // console.log('isTyping===>');
          // console.log(data);
          dispatch({
            type: actions.SET_TYPING,
            data: data,
          });
        });

        socket.on("disconnect", function (err) {
          console.log("err====>");
          console.log(err);
          dispatch({
            type: actions.SET_IO,
            data: null,
            socketData: null,
          });
        });
      }
    }
  },
  getChatList:
    (type, group_id = null) =>
    (dispatch, getState) => {
      const view = getState().App.view;
      const uData = getState().Auth.userData;
      const tabKey = getState().Chat.chatTab;
      const preKeys = getDefaultPath();
      const currentPage =
        isArray(preKeys) && !isEmpty(preKeys) ? preKeys[0] : "";
      const allChats =
        isObject(uData) &&
        uData.user_type === "admin" &&
        currentPage === "all-chats";
      const allGroup =
        isObject(uData) &&
        (uData.user_type === "admin" || uData.user_type === "therapie") &&
        currentPage === "all-groups";
      const apiUrl = allChats
        ? "admin-chat-list"
        : allGroup
        ? "group/group-list"
        : "chat-list";
      dispatch(
        actions.sendRequest(`/chat/${apiUrl}`, null, (data) => {
          const chatLists =
            isObject(data) && isArray(data.data) ? data.data : [];
          dispatch({
            type: actions.SET_CHAT_ROOM,
            data: chatLists,
          });

          if (type === "init" && !allChats) {
            if (view !== "MobileView") {
              dispatch({
                type: actions.SET_SELECTED_CHAT_ROOM,
                data: !isEmpty(chatLists) ? chatLists[0] : {},
              });
            }
          } else if (isObject(type) && !isEmpty(type)) {
            dispatch({
              type: actions.SET_SELECTED_CHAT_ROOM,
              data: type,
            });
          } else if (isNumber(type)) {
            const selectedUser = chatLists.find((item) => item.u_id == type);
            if (selectedUser) {
              dispatch({
                type: actions.SET_SELECTED_CHAT_ROOM,
                data: selectedUser,
              });
            }
          } else if (type === "update") {
            const selectedUser = chatLists.find(
              (item) => item.group_id == group_id
            );
            if (selectedUser) {
              dispatch({
                type: actions.SET_SELECTED_CHAT_ROOM,
                data: selectedUser,
              });
            }
          } else if (!isNumber(type)) {
            const selectedUser = chatLists.find((item) => item.name == type);
            if (selectedUser) {
              dispatch({
                type: actions.SET_SELECTED_CHAT_ROOM,
                data: selectedUser,
              });
            }
          }
        })
      );
    },
  receiveMsg: (msg) => (dispatch, getState) => {
    const preKeys = getDefaultPath();
    const { messages, selectedChatRoom } = getState().Chat;
    const selectedRoom = isObject(selectedChatRoom) ? selectedChatRoom : {};
    const currentPage = isArray(preKeys) && !isEmpty(preKeys) ? preKeys[0] : "";
    const uData = getState().Auth.userData;
    const allGroup =
      isObject(uData) &&
      (uData.user_type === "admin" || uData.user_type === "therapie") &&
      currentPage === "all-groups";
    if (
      msg &&
      !isEmpty(selectedRoom) &&
      ((!allGroup && !msg.group_id && msg.sender_id === selectedRoom.u_id) ||
        (allGroup && msg.group_id === selectedRoom.group_id)) &&
      (currentPage === "chat" || currentPage === "all-groups")
    ) {
      const msgs = isArray(messages) ? [...messages] : [];
      msgs.push(msg);
      dispatch({
        type: actions.SET_MESSAGES,
        data: msgs,
      });
      dispatch({
        type: actions.SET_SELECTED_CHAT_ROOM,
        data: { ...selectedChatRoom, name: msg?.name },
      });
    }

    if (msg && (currentPage === "chat" || currentPage === "all-groups")) {
      dispatch(
        actions.sendRequest("/chat/clear-badge", {
          lead_id: selectedRoom?.group_id
            ? selectedRoom.group_id
            : selectedRoom.lead_id,
          u_id: selectedRoom.u_id,
          type: selectedRoom.group_id ? "group" : "chat",
        })
      );

      dispatch(actions.getChatList());
    }

    return dispatch({
      type: actions.SET_RECEIVED_MSG,
      data: msg,
    });
  },
  sendRequest:
    (url, data = null, callBack = () => {}) =>
    (dispatch, getState) => {
      const IOSocket = getState().Chat.IOSocket;
      const idToken = getState().Auth.idToken;
      const headers = { ...defaultHeaders };
      console.log("REQUEST URL===>");
      console.log(url);
      console.log("REQUEST data===>");
      console.log(data);
      if (idToken && headers) headers.Authorization = `Bearer ${idToken}`;
      const optionsReq = {
        url,
        data,
        method: "POST",
        headers,
      };
      if (IOSocket) {
        IOSocket.request(optionsReq, function (resData, jwres) {
          console.log("Sails responded with: ", url, resData);
          if (
            resData &&
            resData.success === false &&
            resData.message !== "Unauthorized"
          ) {
            Notification("error", resData.message);
          }
          if (resData && resData.message === "Unauthorized") {
            Notification("error", "Token expired, Please login again");
            setTimeout(() => {
              dispatch(authActions.logout());
            }, 500);
          } else {
            callBack(resData);
          }
        });
      }
    },
  setChatNotification: (type) => (dispatch, getState) => {
    // const { selectedChatRoom } = getState().Chat;
    dispatch(
      actions.sendRequest("/chat/getChatList", null, (data) => {
        const chatNotifications =
          isObject(data) && data.chats ? data.chats : [];
        dispatch({
          type: actions.SET_CHAT_NOTIFICATION,
          data: chatNotifications,
        });
      })
    );
  },
  setChatUserList: (data) => ({ type: actions.SET_CHAT_ROOM, data }),
  chatInit: (userId) => ({
    type: actions.CHAT_INIT,
    payload: { userId },
  }),
  toggleCompose: () => ({ type: actions.CHAT_TOGGLE_COMPOSE }),
  toggleViewProfile: (viewProfile) => ({
    type: actions.CHAT_SET_TOGGLE_VIEW_PROFILE,
    viewProfile,
  }),
  setComposedId: (id) => ({ type: actions.CHAT_SET_TOGGLE_COMPOSED_ID, id }),
  setSelectedChatroom: (data) => ({
    type: actions.SET_SELECTED_CHAT_ROOM,
    data,
  }),
  setMessages: (data) => ({
    type: actions.SET_MESSAGES,
    data,
  }),
  sendMessage: (msg) => (dispatch, getState) => {
    const { messages, selectedChatRoom } = getState().Chat;
    const { userData } = getState().Auth;
    const uData = getState().Auth.userData;
    const preKeys = getDefaultPath();
    const currentPage = isArray(preKeys) && !isEmpty(preKeys) ? preKeys[0] : "";
    const allGroup =
      isObject(uData) &&
      (uData.user_type === "admin" || uData.user_type === "therapie") &&
      currentPage === "all-groups";
    if (isObject(selectedChatRoom) && !isEmpty(selectedChatRoom)) {
      const msgObj = {
        createdAt: new Date().getTime(),
        message: msg,
        avtar_url: userData.avtar_url,
        sender_id: userData.id,
        lead_id: selectedChatRoom.lead_id,
        receiver_id: selectedChatRoom.u_id,
        group_id: selectedChatRoom.group_id,
      };
      const msgs = isArray(messages) ? [...messages] : [];
      msgs.push(msgObj);
      return dispatch(
        actions.sendRequest(
          allGroup ? "/chat/group/send-message" : "/chat/send-message",
          msgObj,
          () => {
            dispatch({
              type: actions.SET_MESSAGES,
              data: msgs,
            });
            dispatch(actions.getChatList());
          }
        )
      );
    }
  },

  toggleMobileList: (mobileActiveList) => ({
    type: actions.TOGGLE_MOBILE_LIST,
    mobileActiveList,
  }),
  toggleMobileProfile: (mobileActiveProfile) => ({
    type: actions.TOGGLE_MOBILE_PROFILE,
    mobileActiveProfile,
  }),
  restoreData: (demoData) => ({
    type: actions.RESTORE_DEMO_DATA,
    demoData,
  }),
  updateNewUsersProp: (addNewUsersProp) => ({
    type: actions.UPDATE_NEW_USER_PROPS,
    addNewUsersProp,
  }),
  addNewUser: (user, addNewUsersProp) => ({
    type: actions.ADD_NEW_USER,
    user,
    addNewUsersProp,
  }),
  setCurrentPage: (page) => ({
    type: actions.SET_CURRENT_PAGE,
    page,
  }),
  clearChatData: () => ({
    type: actions.CLEAR_CHAT_DATA,
  }),
};
export default actions;
