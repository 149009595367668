import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { isEmpty, isObject } from 'lodash';
import { IntlProvider, addLocaleData } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';
import getApiData from '@iso/lib/helpers/apiHelper';
import deDe from 'antd/lib/locale-provider/de_DE';
import langActions from '@iso/redux/languageSwitcher/actions';
import AppLocale from '@iso/config/translation';
import { getToken } from '@iso/lib/helpers/utility';
import authActions from '@iso/redux/auth/actions';

const { setAllWords } = langActions;
const { setUserObj } = authActions;

export default function AppProvider({ children }) {
  const language = useSelector((state) => state.LanguageSwitcher.language);
  const dispatch = useDispatch();
  // const allWords = useSelector((state) => state.LanguageSwitcher.allWords);
  const [messages, setMessages] = useState({});
  const { themeName } = useSelector(
    (state) => state.ThemeSwitcher.changeThemes
  );
  const lang_code = language && language.lang_code ? language.lang_code : 'de';
  const currentAppLocale = AppLocale['de'];
  const token = getToken().get('idToken');
  const user = useSelector((state) => state.Auth.userData);
  const userType = isObject(user) && user.user_type ? user.user_type : '';

  useEffect(() => {
    const localeData = require(`react-intl/locale-data/${lang_code}`);
    addLocaleData(localeData.default);

    // console.log('==================== GET ALL WORDS IN ONE TIME =================');
    async function fetchLanngWord() {
      try {
        const res = await getApiData(
          'trans/getAllWords',
          { langCode: lang_code },
          'POST'
        );
        if (res.success && res.data) {
          setMessages(res.data);
          dispatch(setAllWords(res.data));
        }
      } catch (err) {
        console.log('err====?');
        console.log(err);
      }
    }
    fetchLanngWord();
  }, [lang_code]);

  // this function for get user data
  async function getUserData() {
    try {
      const response = await getApiData('getTherapieDetail', {}, 'POST');
      if (response.success && !isEmpty(response.user)) {
        dispatch(setUserObj(response.user, token));
      }
    } catch (err) {
      console.log('err====?');
      console.log(err);
    }
  }

  useEffect(() => {
    if (token !== '' && userType === 'therapie') {
      getUserData();
    }
  }, [token]);

  (async () => {
    const localeData = await import(`react-intl/locale-data/${lang_code}`);
    addLocaleData(localeData.default);
  })();

  return (
    <ConfigProvider locale={lang_code === 'de' ? deDe : undefined}>
      <IntlProvider
        locale={lang_code}
        messages={
          isObject(messages) && !isEmpty(messages)
            ? messages
            : currentAppLocale.messages
        }
      >
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
