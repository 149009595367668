const prod = process.env.NODE_ENV === "production";
let staging = false;

// let apiDomain = "http://192.168.2.101:1337"; // LOCAL (MAYUR)
let apiDomain = "https://api.start-online-therapie.de"; // LIVE

// if (staging) apiDomain = 'http://api.staging.start-online-therapie.de';
if (staging) apiDomain = "http://192.168.0.170:1337";

export default {
  siteName: "Online Therapie",
  siteIcon: "ion-flash",
  footerText: `© ${new Date().getFullYear()} start: Psychotherapy & Coaching GmbH`,
  enableAnimatedRoute: false,
  apiUrl: `${apiDomain}/api/`,
  siteUrl: "https://start-online-therapie.de/",
  domainUrl: `${apiDomain}/`,
  sailsUrl: `${apiDomain}`,
  google: {
    analyticsKey: "UA-xxxxxxxxx-1",
  },
  tinyEditorKey: "8785dy83rph1fw81kgft39bjgw6x2ck6ugr8wi252kvmyiwz",
  dashboard: "/dashboard",
  stripeApiKey: prod
    ? "pk_live_LTOhoxY3sky39siD1f2f1MYo"
    : "pk_test_yXTdW39gTmIe5ImblCYJqZd5006LYH6guw",
  paypalClientId: prod
    ? "AWvi5ahxM1maMVQhoJsEsOSaMES5ZVo_VmPzsM8ylwQFJwTht5GiaEWcRLsnLRjjEml0TeQm6mus1g0n"
    : "AbKwV8EdQKSyDq38H6AQbRzrCA01U9pnI9jiGr-8iqiIfVAkEiXot_7wLeMxyru3bPxYNSXvmrWhnRRM",
};
