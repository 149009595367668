import { getParsedJson } from '@iso/lib/helpers/utility';
import actions from './actions';

const initState = { idToken: null, userData: {}, loggedOut: false };

export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.LOGIN_SUCCESS:
			return {
				idToken: action.token,
				userData: action.user ? getParsedJson(action.user) : {}
			};
		case actions.LOGOUT:
			return initState;
		case actions.SET_LOGGED_OUT:
			return {
				...initState,
				loggedOut: action.loading,
			};
		default:
			return state;
	}
}
